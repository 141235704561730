import React from "react";
import translate from "../../i18n/translate";
import CustomButton from "../Shared/CustomButton/CustomButton";
import arrowRightWhite from "../../images/arrowRightWhite.svg";
import cdkday from "../../images/cdkday.png";
import openit from "../../images/openit.png";
import awscommunityday from "../../images/aws-community-day.png";
import awsusergroups from "../../images/aws-user-groups.png";
import hug from "../../images/hug.png";

export const communities = [
  {
    img: hug,
    desc: translate("community-hug"),
    btn: (
      <CustomButton
        text={translate("community-button")}
        image={arrowRightWhite}
        externalLink={
          "https://www.meetup.com/de-DE/Hamburg-HashiCorp-User-Group/"
        }
      />
    ),
  },
  {
    img: awscommunityday,
    desc: translate("community-awscommunityday"),
    btn: (
      <CustomButton
        text={translate("community-button")}
        image={arrowRightWhite}
        externalLink={"https://aws.amazon.com/events/community-day/"}
      />
    ),
  },
  {
    img: cdkday,
    desc: translate("community-cdkday"),
    btn: (
      <CustomButton
        text={translate("community-button")}
        image={arrowRightWhite}
        externalLink={"https://www.cdkday.com/"}
      />
    ),
  },
  {
    img: awsusergroups,
    desc: translate("community-awsusergroups"),
    btn: (
      <CustomButton
        text={translate("community-button")}
        image={arrowRightWhite}
        externalLink={"https://aws.amazon.com/developer/community/usergroups/"}
      />
    ),
  },
  {
    img: openit,
    desc: translate("community-openit"),
    btn: (
      <CustomButton
        text={translate("community-button")}
        image={arrowRightWhite}
        externalLink={"https://open-it.rs/"}
      />
    ),
  },
];

export const communitiesDe = [
  {
    img: hug,
    desc: "HashiCorp User Groups (HUG) sind die Eckpfeiler der Community: regelmäßige Treffen, die von Einheimischen abgehalten werden, freiwillige Organisatoren, die sich leidenschaftlich für HashiCorp-Tools und Wissens- und Erfahrungsaustausch einsetzen. Wir sind Gründer und Organisatoren der HUG in Hamburg - ein vierteljährliches Treffen voller interessanter Gespräche und Einblicke rund um HashiCorp, ein Ort, um Wissen zu teilen, Gedanken auszutauschen und sich mit Gleichgesinnten zu vernetzen.",
    btn: (
      <CustomButton
        text={"Besuche "}
        image={arrowRightWhite}
        externalLink={
          "https://www.meetup.com/de-DE/Hamburg-HashiCorp-User-Group/"
        }
      />
    ),
  },
  {
    img: awscommunityday,
    desc: "AWS Community Day-Veranstaltungen sind von der Community geleitete Konferenzen, bei denen die Veranstaltungslogistik und die Inhalte von führenden Vertretern der Community geplant, beschafft und bereitgestellt werden. Community Day-Veranstaltungen bieten eine Peer-to-Peer-Lernerfahrung und bieten Entwicklern einen Ort, an dem sie AWS-Wissen auf die von ihnen bevorzugte Weise erwerben können: von anderen.",
    btn: (
      <CustomButton
        text={"Besuche "}
        image={arrowRightWhite}
        externalLink={"https://aws.amazon.com/events/community-day/"}
      />
    ),
  },
  {
    img: cdkday,
    desc: "Der CDK Day ist eine jährliche Communityveranstaltung über AWS CDK, cdktf, projen und cdk8s. Verschiedene Speaker mit unterschiedlichem Hintergrund repräsentieren das gesamte Spektrum an Erfahrungen,von “meinen ersten Erfahrungen mit CDK” bis hin zu “meinen Erfahrungen als Top-10-Core-AWS-CDK-Mitarbeiter”. - jeder ist willkommen. Der Fokus in den Sessions liegt auf Demos! Mehr Code und weniger Folien.",
    btn: (
      <CustomButton
        text={"Besuche "}
        image={arrowRightWhite}
        externalLink={"https://www.cdkday.com/"}
      />
    ),
  },
  {
    img: awsusergroups,
    desc: "AWS-fokussierte Benutzergruppen sind eine weltweit Gemeinschaft von Entwicklern und Führungskräften, die durch ihr gemeinsames Interesse vereint sind, Erfahrungen auszutauschen, neue Services und Best Practices kennenzulernen, Beziehungen aufzubauen, und sogar Freundschaften zu schließen. Unser Team ist aktives Mitglied der AWS-Communities in Hamburg, Berlin und Belgrad und trägt mit Vorträgen, Podiumsdiskussionen oder sogar Event-Sponsoring bei.",
    btn: (
      <CustomButton
        text={"Besuche "}
        image={arrowRightWhite}
        externalLink={"https://aws.amazon.com/developer/community/usergroups/"}
      />
    ),
  },
  {
    img: openit,
    desc: "Open IT ist die größte serbische IT-Konferenz für Studenten. An zwei Tagen treffen sich 300 Studenten und 20 Unternehmen . Mit unser Unterstützung, versuchen, wir die IT-Szene in Serbien einen Schritt weiter zu bringen! OpenIT wurde mit der Idee gegründet, jungen IT-Studenten bei ihrem nächsten Schritt zu helfen, ihr Potenzial zu entwickeln, ihnen die Möglichkeit geben, ihre Leidenschaft in verschiedenen Bereichen der IT zu entdecken, die neuesten Technologien kennenzulernen und sich mit Menschen und Unternehmen zu vernetzen.",
    btn: (
      <CustomButton
        text={"Besuche "}
        image={arrowRightWhite}
        externalLink={"https://open-it.rs/"}
      />
    ),
  },
];

export const communitiesEng = [
  {
    img: hug,
    desc: "HashiCorp User Groups (HUG) are the cornerstones of the community: regular meetings hosted by locals, volunteer organizers who are passionate about HashiCorp tools and sharing knowledge and experiences. We are the founders and organizers of HUG in Hamburg - a quarterly meeting full of interesting conversations and insights all about HashiCorp, a place to share knowledge, exchange thoughts and network with like-minded people.",
    btn: (
      <CustomButton
        text={"Visit "}
        image={arrowRightWhite}
        externalLink={
          "https://www.meetup.com/de-DE/Hamburg-HashiCorp-User-Group/"
        }
      />
    ),
  },
  {
    img: awscommunityday,
    desc: "AWS Community Day events are community-led conferences where event logistics and content is planned, sourced, and delivered by community leaders. Community Day events deliver a peer-to-peer learning experience, providing developers with a venue for them to acquire AWS knowledge in their preferred way: from one another.",
    btn: (
      <CustomButton
        text={"Visit "}
        image={arrowRightWhite}
        externalLink={"https://aws.amazon.com/events/community-day/"}
      />
    ),
  },
  {
    img: cdkday,
    desc: "The CDK Day is a yearly community event about AWS CDK, cdktf, projen and cdk8s. Different speakers from diverse backgrounds representing the whole spectrum of experience, from  'my first experience using cdk' up to 'my experience as a top 10 core AWS CDK contributor' - everyone is welcome. The fokus in the sessions are Demos! More code and less slides. ",
    btn: (
      <CustomButton
        text={"Visit "}
        image={arrowRightWhite}
        externalLink={"https://www.cdkday.com/"}
      />
    ),
  },
  {
    img: awsusergroups,
    desc: "AWS-focused user groups are a world-wide community of developers and executives who are united by their interest to share experiences, learn about new services and best practices, build relationships, and even make friend. Our team is active member of the AWS communities in Hamburg, Berlin and Belgrade and contributes with talks, panel discussions or even event sponsoring.",
    btn: (
      <CustomButton
        text={"Visit "}
        image={arrowRightWhite}
        externalLink={"https://aws.amazon.com/developer/community/usergroups/"}
      />
    ),
  },
  {
    img: openit,
    desc: "Open IT is the largest Serbian IT conference for students. 300 students and 20 companies meet over two days. With our support, we try to bring the IT scene in Serbia one step further! OpenIT was founded with the idea of ​​helping young IT students to take their next step, to develop their potential, to give them the opportunity to discover their passion in different areas of IT, to learn about the latest technologies and to network with people and companies.",
    btn: (
      <CustomButton
        text={"Visit "}
        image={arrowRightWhite}
        externalLink={"https://open-it.rs/"}
      />
    ),
  },
];

export const community = {
  title: translate("community-description-header"),
  description: translate("community-description"),
};
