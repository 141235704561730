import React from "react";
import CommunityMain from "../components/CommunityUiComponents/CommunityMain";
import Layout from "../components/Layout/Layout";
import { SEO } from "../components/seo";
import tableDesktop from "../images/community.jpg";
const Community = () => {
  return (
    <Layout location="/community">
      <SEO
        title="Community"
        description="Learning from each other and growing together"
        type="website"
        image={tableDesktop}
      />
      <CommunityMain />
    </Layout>
  );
};

export default Community;
