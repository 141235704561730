// extracted by mini-css-extract-plugin
export var PartnerMobileCarousel = "CommunitiesSlider-module--PartnerMobileCarousel--L1LqD";
export var PartnersCarouselDesc = "CommunitiesSlider-module--PartnersCarouselDesc--5KnBw";
export var blogArrowContainer = "CommunitiesSlider-module--blogArrowContainer--0bSTQ";
export var btnDiv = "CommunitiesSlider-module--btnDiv--+aqGd";
export var carousel = "CommunitiesSlider-module--carousel--qD9EX";
export var carouselDiv = "CommunitiesSlider-module--carouselDiv--j8ewn";
export var carouselImage = "CommunitiesSlider-module--carouselImage--zQXpD";
export var carouselImageDiv = "CommunitiesSlider-module--carouselImageDiv--ghhuA";
export var carouselImg = "CommunitiesSlider-module--carouselImg--hhcIU";
export var carouselImgDivCommunities = "CommunitiesSlider-module--carouselImgDivCommunities--XGbPc";
export var carouselMainDiv = "CommunitiesSlider-module--carouselMainDiv--U3kp6";
export var carousel__container = "CommunitiesSlider-module--carousel__container--CQvX3";
export var carousel__slider = "CommunitiesSlider-module--carousel__slider--VMwHK";
export var carousel__sliderTrayWrapHorizontal = "CommunitiesSlider-module--carousel__slider-tray-wrap--horizontal--M8xbs";
export var carousel__sliderTrayWrapper = "CommunitiesSlider-module--carousel__slider-tray-wrapper---rbLb";
export var caseStudiesDescription = "CommunitiesSlider-module--caseStudiesDescription--Chk3Z";
export var contactButton = "CommunitiesSlider-module--contactButton--QWt82";
export var paragraph = "CommunitiesSlider-module--paragraph---83DK";
export var partnersMainSlider = "CommunitiesSlider-module--partnersMainSlider--yukd+";
export var subDiv = "CommunitiesSlider-module--subDiv--BGXfR";