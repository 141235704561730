import React, { useRef, FC } from "react";
import loadable from "@loadable/component";
import * as styles from "../CommunitiesSlider.module.scss";
import { UseWindowSize } from "../../hooks/UseWindowSize";
import cx from "classnames";
import greenArrowRightBlog from "../../../images/greenArrowRightBlog.svg";
import arrowGreenLeftBlog from "../../../images/arrowGreenLeftBlog.svg";

type Props = {
  data: any;
};

const Carousel = loadable(() => import("react-tiny-slider"));

const PartnersTechnologyMobileSlider: FC<Props> = ({ data }) => {
  const width = UseWindowSize();
  const carouselBlog = useRef(null);

  const goNextSlide = () => {
    carouselBlog.current.goTo("next");
  };
  const goPreviousSlide = () => {
    carouselBlog.current.goTo("prev");
  };
  return (
    <div className={cx(styles.carouselMainDiv, "partners_mobile_carousel")}>
      <div className={styles.blogArrowContainer}>
        <img
          src={arrowGreenLeftBlog}
          onClick={() => goPreviousSlide()}
          alt=""
        />
        <img src={greenArrowRightBlog} onClick={() => goNextSlide()} alt="" />
      </div>
      <Carousel
        swipeAngle={false}
        items={width.width <= 440 ? 1 : width.width < 600 ? 1.2 : 2}
        mouseDrag
        loop={true}
        gutter={15}
        ref={carouselBlog}
        controls={false}
        nav={false}
        style={{ overflow: "hidden" }}
      >
        {data?.map((node: any, index: number) => {
          return (
            <div key={index} className={styles.carouselDiv}>
              <div className={styles.carouselImgDivCommunities}>
                <img
                  src={node?.img}
                  alt="cloudInsigtsImage"
                  className={styles.carouselImg}
                />
              </div>
              <div className={styles.paragraph}>
                <p className={styles.PartnerMobileCarousel}>{node?.desc}</p>
                <a href={node.btn} target="_blank" rel="noreferrer">
                  {node.btn}
                </a>
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default PartnersTechnologyMobileSlider;
