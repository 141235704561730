import React, { FC, useContext } from "react";
import * as styles from "./CommunityMain.module.scss";
import tableDesktop from "../../images/community.jpg";
import tableDesktopMobile from "../../images/mobile-images/community.webp";
import { UseWindowSize } from "../hooks/UseWindowSize";
import ServerlessSummitImg from "../../images/serverlesssummit.png";
import CommunityMainImg from "../../images/community-main.jpg";
import awscommunitybuilderImg from "../../images/communitybuilderlogo.png";
import awsambassadorImg from "../../images/awsambassadors.png";
import hashicorpambassadorImg from "../../images/hashicorpambassador.png";
import InnerWrapper from "../Shared/InnerWrapper/InnerWrapper";
import CircleOutline from "../Shared/CircleOutlineAndBlur/CircleOutline";
import CircleBlur from "../Shared/CircleOutlineAndBlur/CircleBlur";
import HeroBanner from "../Shared/HeroBanner/HeroBanner";
import HeroBannerMobile from "../Shared/HeroBannerMobile/HeroBannerMobile";
import translate from "../../i18n/translate";
import { LanguageContext } from "../../context/languageContext";
import CommunitiesMobileSlider from "../OptimizationUiComponents/Slider/CommunitiesMobileSlider";
import CommunitiesSlider from "../OptimizationUiComponents/Slider/PartnersTechnologySlider";
import CaseStudiesSliderButtons from "../HomeUiComponents/CaseStudiesMain/Slider/CaseStudiesSliderButtons";
import { CarouselProvider, Slider } from "pure-react-carousel";
import CustomButton from "../../../src/components/Shared/CustomButton/CustomButton";
import arrowRightWhite from "../../images/arrowRightWhite.svg";

import {
  communities,
  communitiesEng,
  communitiesDe,
  community,
} from "./CommunityMain.helper";

const CommunityMain: FC = () => {
  const width = UseWindowSize();
  const { language } = useContext(LanguageContext);

  return (
    <div className={styles.partners}>
      {width.width < 1200 ? (
        <HeroBannerMobile
          heading={translate("community-heading")}
          description={translate("community-heading-description")}
          homeBackground={tableDesktopMobile}
          alt={"Community"}
        />
      ) : (
        <HeroBanner
          heading={translate("community-heading")}
          description={translate("community-heading-description")}
          homeBackground={tableDesktop}
        />
      )}
      <InnerWrapper>
        <div className={styles.knowUs}>
          <div className={styles.knowUsImage}>
            <div className={styles.knowUsSubDiv}>
              <img
                src={CommunityMainImg}
                alt="Community Main"
                className={styles.awsMainImage}
              />
            </div>
          </div>
          <div className={styles.knowUsText}>
            <h2 className="heading">.{community?.title}</h2>
            <p>{translate("community-description")}</p>
            <CircleOutline
              isBlurVisible={true}
              initialScale={0.3}
              animateScale={1}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.heroBackgroundCircleLeft}
            />
            <CircleBlur
              initialScale={0.1}
              initialOpacity={0}
              animateScale={1.8}
              animateOpacity={0.15}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.heroBackgroundInnerCircleLeft}
            />
          </div>
        </div>
        <div className={styles.knowUs}>
          <div className={styles.knowUsText}>
            <h2 className="heading">
              <span>.</span>
              {translate("awards-header")}
            </h2>
            <p>{translate("awards-description")}</p>
          </div>
          <div style={{ alignSelf: "center" }}>
            <a
              target="_blank"
              href="https://aws.amazon.com/developer/community/community-builders/community-builders-directory/?cb-cards.sort-by=item.additionalFields.cbName&cb-cards.sort-order=asc&awsf.builder-category=*all&awsf.location=*all&awsf.year=*all&cb-cards.q=david%2Bkrohn&cb-cards.q_operator=AND"
              rel="noreferrer"
              title="Find our Community Builders here"
            >
              <img
                src={awscommunitybuilderImg}
                alt="AWS Community Builders Logo"
                className={styles.awscommunityawardslogo}
              />
            </a>
            <a
              href="https://www.linkedin.com/in/mhmdio/"
              rel="noreferrer"
              target="_blank"
              title="Find our Hashicorp Ambassador here"
            >
              <img
                src={hashicorpambassadorImg}
                alt="Hashicorp Ambassador Logo"
                className={styles.hashicorpcommunityawardslogo}
              />
            </a>
            <a
              target="_blank"
              href="https://aws.amazon.com/partners/ambassadors/?cards-body.sort-by=item.additionalFields.ambassadorName&cards-body.sort-order=asc&awsf.apn-ambassadors-location=*all&cards-body.q=globaldatanet&cards-body.q_operator=AND"
              rel="noreferrer"
              title="Find our AWS Ambassador here"
            >
              <img
                src={awsambassadorImg}
                alt="AWS Ambassador Logo"
                className={styles.awscommunityawardslogo}
              />
            </a>
          </div>
        </div>

        <div className={styles.knowUs}>
          <div className={styles.knowUsImage}>
            <div className={styles.knowUsSubDiv}>
              <img
                src={ServerlessSummitImg}
                alt="Community Main Aws"
                className={styles.awsMainImage}
              />
            </div>
          </div>
          <div className={styles.knowUsText}>
            <h2 className="heading">
              <span>.</span>
              Serverless Summit
            </h2>
            <p>{translate("community-serverless-summit")}</p>
            <CircleOutline
              isBlurVisible={true}
              initialScale={0.3}
              animateScale={1}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.heroBackgroundCircleLeft}
            />
            <CircleBlur
              initialScale={0.1}
              initialOpacity={0}
              animateScale={1.8}
              animateOpacity={0.15}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.heroBackgroundInnerCircleLeft}
            />

            <CustomButton
              buttonClass={styles.buttonClasses}
              text={translate("community-button")}
              image={arrowRightWhite}
              externalLink={"https://serverless-summit.io"}
            />
          </div>
        </div>
        <div className={styles.partnersBenefits}>
          <div className={styles.partnersBenefitsTech}>
            <h2 className="heading">
              <span>.</span>
              {translate("community-communitiesHeading")}
            </h2>
            <CircleOutline
              isBlurVisible={true}
              initialScale={0.3}
              animateScale={1}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.heroBackgroundCircleLeft}
            />
            <CircleBlur
              initialScale={0.1}
              initialOpacity={0}
              animateScale={1.8}
              animateOpacity={0.3}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.heroBackgroundInnerCircleLeft}
            />
            <div className={styles.carouselContainer}>
              {width.width <= 770 ? (
                <CommunitiesMobileSlider
                  data={language === "EN" ? communitiesEng : communitiesDe}
                />
              ) : (
                <CarouselProvider
                  className={"partners_carousel__container carousel__container"}
                  naturalSlideWidth={120}
                  naturalSlideHeight={125}
                  totalSlides={communities.length}
                  visibleSlides={
                    width.width > 1700 ? 3 : width.width > 1200 ? 3 : 2
                  }
                  currentSlide={0}
                  infinite
                >
                  <CaseStudiesSliderButtons
                    sliderButtonsClassName={styles.partnersButtonsContainer}
                  />
                  <Slider
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {communities?.map((item, index) => {
                      return (
                        <CommunitiesSlider
                          key={index}
                          sliderIndex={index}
                          sliderImage={item?.img}
                          about={item?.desc}
                          btn={item?.btn}
                        />
                      );
                    })}
                  </Slider>
                </CarouselProvider>
              )}
            </div>
          </div>
        </div>
      </InnerWrapper>
      <div></div>
    </div>
  );
};

export default CommunityMain;
