// extracted by mini-css-extract-plugin
export var awsExpertImg = "CommunityMain-module--awsExpertImg--9791W";
export var awsExpertImgDiv = "CommunityMain-module--awsExpertImgDiv--8n0lt";
export var awsMainImage = "CommunityMain-module--awsMainImage--NIzcf";
export var awscommunityawardslogo = "CommunityMain-module--awscommunityawardslogo--ETC9P";
export var businessImage = "CommunityMain-module--businessImage--TQHKi";
export var businessItem = "CommunityMain-module--businessItem--CQ7VJ";
export var businessItemImage = "CommunityMain-module--businessItemImage--sZN18";
export var businessItemMain = "CommunityMain-module--businessItemMain--whD7a";
export var businessMain = "CommunityMain-module--businessMain--lvxs1";
export var businessText = "CommunityMain-module--businessText--bfmm1";
export var buttonClasses = "CommunityMain-module--buttonClasses--nQrLk";
export var carouselContainer = "CommunityMain-module--carouselContainer--TZpFX";
export var caseChallengeImage = "CommunityMain-module--caseChallengeImage--0h4CF";
export var caseTestimonialImage = "CommunityMain-module--caseTestimonialImage--dn8vx";
export var contactButton = "CommunityMain-module--contactButton--PNE+V";
export var detailsBoxLeft = "CommunityMain-module--detailsBoxLeft--NxolJ";
export var hashicorpcommunityawardslogo = "CommunityMain-module--hashicorpcommunityawardslogo--WrSB1";
export var heroBackgroundCircleLeft = "CommunityMain-module--heroBackgroundCircleLeft--tSscS";
export var heroBackgroundInnerCircleLeft = "CommunityMain-module--heroBackgroundInnerCircleLeft--MXBEU";
export var heroWhiteBlurCirce = "CommunityMain-module--heroWhiteBlurCirce--14uGt";
export var includedStudiesImage = "CommunityMain-module--includedStudiesImage--Ih7FQ";
export var knowUs = "CommunityMain-module--knowUs--FBwHW";
export var knowUsImage = "CommunityMain-module--knowUsImage--jNMox";
export var knowUsSubDiv = "CommunityMain-module--knowUsSubDiv--4vgWZ";
export var knowUsText = "CommunityMain-module--knowUsText--OU+iV";
export var partners = "CommunityMain-module--partners--wy6KO";
export var partnersBenefits = "CommunityMain-module--partnersBenefits--Aerjy";
export var partnersBenefitsCarousel = "CommunityMain-module--partnersBenefitsCarousel--ds2BL";
export var partnersBenefitsInner = "CommunityMain-module--partnersBenefitsInner--Jj2pm";
export var partnersBenefitsItem = "CommunityMain-module--partnersBenefitsItem--CoYHN";
export var partnersBenefitsItemBusiness = "CommunityMain-module--partnersBenefitsItemBusiness--XbQ2q";
export var partnersBenefitsItemBusinessTop = "CommunityMain-module--partnersBenefitsItemBusinessTop--u5JnB";
export var partnersBenefitsTech = "CommunityMain-module--partnersBenefitsTech--Wchta";
export var partnersButtonsContainer = "CommunityMain-module--partnersButtonsContainer--BRxHb";
export var partnersMainSlider = "CommunityMain-module--partnersMainSlider--icMMo";
export var reportDetailsBoxRight = "CommunityMain-module--reportDetailsBoxRight--jDexv";
export var singleBenefitTextBox = "CommunityMain-module--singleBenefitTextBox--dyge2";
export var singleCaseChallenge = "CommunityMain-module--singleCaseChallenge--QAUiq";
export var singleCaseContentBlock = "CommunityMain-module--singleCaseContentBlock--xkWPa";
export var singleCaseImageBox1 = "CommunityMain-module--singleCaseImageBox1--1Vu5E";
export var singleCaseSolution = "CommunityMain-module--singleCaseSolution--X3Y0W";
export var singleCaseTestimonial = "CommunityMain-module--singleCaseTestimonial--x4co4";
export var singleCaseTextBox = "CommunityMain-module--singleCaseTextBox--u6UJ1";
export var singleReportDetails = "CommunityMain-module--singleReportDetails--z0xaB";
export var testimonialContent = "CommunityMain-module--testimonialContent--p0zLn";